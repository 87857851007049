<template>
    <div class="middle-box text-center loginscreen animated fadeInDown">
        <p class="logo"><img src="@/assets/img/logo-trinch.png" alt="Trinch" width="200"></p>
        <h3>Identifiez vous.</h3>
        <div class="error" v-if="error">
          Erreur de connexion
        </div>
        <div class="form">
            <form @submit.prevent="login">
                <div class="form-group">
                    <input v-model="username" type="text" class="form-control" id="identifiant" placeholder="Votre identifiant">
                </div>
                <div class="form-group">
                    <input v-model="password" type="password" class="form-control" id="password" placeholder="Mot de passe">
                </div>
                <div class="form-group" v-if="!loading">                        
                    <button type="submit" class="btn btn-primary">Me connecter</button>
                </div>
                <div class="form-group">
                    <router-link to="/forgot-password"><small>Mot de passe oublié ?</small></router-link>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
import { AUTH_REQUEST } from "../store/actions/auth";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      error: false,
      loading: false
    };
  },
  methods: {
    login: function() {
      this.loading = true
      this.error = false

      const { username, password } = this;
      this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
        this.loading = false;
        this.$router.push("/");
      }).catch( () => {      
        this.error = true;
        this.loading = false;
      });
    }
  }
};
</script>
